@import '@styles/_variables.scss';
.el-loginBox {
  label {
    font-size: $font-size-default;
    // font-weight: $font-weight-bold;
    transform: inherit;
  }
}
.el-loginField {
  label {
    font-size: $font-size-default;
    // font-weight: $font-weight-bold;
  }
}
.el-googleLogin {
  border-color: $gray-pastel !important;
  color: $gray-menu !important;
  font-weight: 600 !important;
  font-size: $font-size-md !important;
  img {
    max-width: $spacing-xl;
  }
}

.El-ProfileFieldBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  .MuiInputBase-root {
    border: 1px solid #ccc;
    margin-top: 22px;
    border-radius: 4px;

    &:before {
      display: none;
    }
  }
  .MuiFormControl-root {
    min-width: 46%;
    input {
      font-weight: 300;
      height: 39px;
      padding-left: 8px;
    }
    .MuiAlert-icon {
      margin: 0 4px 0px 0px;
      display: flex;
      align-items: center;
      svg {
        font-size: 14px;
        color: #10b981;
      }
    }
    .MuiButton-textSecondary {
      color: #2563eb;
      font-weight: 600;
    }
    .MuiAlert-message {
      color: #10b981;
    }
    .MuiFormLabel-root {
      font-size: 18px;
    }
  }
}
.el_Dit {
  padding: 12px 0 0px 0px;
  text-align: center;
  font-size: 14px;
  color: #666;
  button {
    font-size: 14px;
    font-weight: 600;
    border-radius: 0px;
    padding-bottom: 0px;
    padding: 0px;
    color: #666;
    text-decoration: underline;
    text-transform: none !important;
  }
}

// menu css start
@media (min-width: 768px) {
}
@media (max-width: 767px) {
  .El-ProfileFieldBox {
    display: block;
    width: 100%;
    padding: 0px;
    .MuiFormControl-root {
      min-width: inherit;
      display: block;
      padding-bottom: 20px;
    }
    .EL-ProfileConfirm {
      border-radius: 20px;
      box-shadow: none;
      background: #000;
      text-transform: capitalize;
    }
    .el-profileField {
      .MuiFormControl-root {
        display: block;
        padding-bottom: 16px;
      }
    }
  }
}
