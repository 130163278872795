@import '@styles/_variables.scss';
.amenity {
  border: none !important;
  img {
    max-width: 100%;
    opacity: 0.6;
  }
  span {
    font-size: $font-size-default;
    color: $gray-medium-dark;
    white-space: nowrap;
    padding-right: 0px;
  }
  .MuiChip-avatar {
    border-radius: 0px;
    width: 26px;
    height: 26px;
  }
}
