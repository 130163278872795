@import '@styles/_variables.scss';
.HomePropertySkeleton {
  &.slekton-flex {
    & div {
      margin-right: inherit;
    }
  }
  margin-bottom: 12px;

  .full-width {
    width: 100% !important;
  }

  .listingSkeleton {
    box-shadow: 0px 2px 1.25rem rgba(0, 0, 0, 0.06);
    transition: inherit;
    border-radius: 4px;
    overflow: hidden !important;
    margin-bottom: 0.25rem;
    position: relative;
    margin-left: 32px;
    margin-right: 0px;

    width: auto;
    max-width: 94%;
    padding: 8px;
    transform-origin: inherit;
    -webkit-transform: inherit;
    transform: inherit;
    &:first-child {
      margin-left: 0px;
    }
    .MuiSkeleton-root {
      transform-origin: inherit;
      -webkit-transform: inherit;
      transform: inherit;
      &:first-child {
        // -webkit-border-top-left-radius: 16px;
        // -webkit-border-top-right-radius: 16px;
        // -moz-border-radius-topleft: 16px;
        // -moz-border-radius-topright: 16px;
        // border-top-left-radius: 16px;
        // border-top-right-radius: 16px;
      }
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    // padding: 16px;
    // width: auto;
  }
}

.EL-homebannerMain {
  min-height: 84vh;
  padding-bottom: 0px;
  background-color: #f5f6f7;
  position: relative;
  display: flow-root !important;
  .EL-propertyFilterbar {
    position: relative;
    z-index: 2;
    margin-top: -140px;
    bottom: inherit;
    top: inherit;
    box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.3);
    padding: 0px;
    max-width: 1170px !important;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    border-radius: 12px;
    @media (max-width: 768px) {
      position: absolute;
      margin-top: -30px;
      padding: 0 16px;
      box-shadow: none;
    }
  }
  @media (max-width: 768px) {
    min-height: 446px !important;
    margin-bottom: 0px;
    .swiper-pagination {
      bottom: 40px !important;
    }
  }
}
.EL-propertyFilterbarMobile {
  background-color: #f5f6f7;
  padding: 24px 0px 16px 0px;
}
.section-bg {
  background-color: #f5f6f7;
}
.propertySlelenton {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  & > div {
    width: 100%;
    margin-right: $spacing-lg;
  }
}
.lazyProgress {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-section {
  padding: 80px 0 80px 0;
  overflow: hidden;
  &.EL-AxisbankOffer {
    padding: 36px 0px 0 0px;
    @media (max-width: 767px) {
      &.section-bg {
        padding: 0px 0px 0 0px;
        margin-top: 44px;
        position: relative;
      }
    }
  }
  &.el-testimonials {
    padding-top: 0px;
  }
  &.offerSection {
    // padding-bottom: 0px;
  }
}
.EL-bannerSkeleton {
  width: 100%;
  height: 84vh;
  .MuiSkeleton-root {
    transition: inherit;
    -webkit-transform: inherit;
  }
}
.elMainBanner {
  min-height: 84vh;
  .sliderItem {
    min-height: 84vh;
    a {
      max-width: 100%;
      border-radius: 0px;
      margin: 0px;
      padding: 0px;
    }
  }
  a,
  button {
    border-radius: 30px;
    max-width: 300px;
    &:hover {
      background-color: $primary-white;
    }
  }
  h2 {
    display: none;
    position: absolute;
    width: 60%;
    left: 20%;
    color: $primary-white;
    top: 30%;
    font-size: 50px;
    text-align: center;
    z-index: 2;
  }
  .sliderItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 84vh;
    margin-bottom: $spacing-md;
    img {
      height: 84vh;
      width: 100%;
      cursor: pointer;
    }
  }
  & span {
    &.el-hometitle {
      display: block;
      position: relative;
      padding-bottom: 30px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: $spacing-14xl;
        height: $spacing-3xs;
        background: $color-primary;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  a,
  button {
    clear: both;
    margin-top: $spacing-9xl;
    background-color: $primary-white;
    color: $gray-dark;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    text-transform: capitalize;
    svg {
      font-size: $font-size-3xl;
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      margin: 0 2px;
      color: #fff;
      background-color: #fff;
    }
  }
  .swiper-button-next {
    right: 30px;
  }
  .swiper-button-prev {
    left: 30px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      color: #fff;
      font-size: 60px;
    }
  }
}

.section-header {
  text-align: center;
  padding-bottom: 50px;

  h2,
  h1,
  h3 {
    font-size: $font-size-7xl;
    font-weight: 700;
    position: relative;
    color: $color-secondary;
    margin-top: 0px;
    margin-bottom: $spacing-sm;
    &:before,
    &:after {
      content: '';
      width: 50px;
      height: 2px;
      background: $color-primary;
      display: inline-block;
    }
    &:before {
      margin: 0 15px 10px 0;
    }
    &:after {
      margin: 0 0 10px 15px;
    }
  }
  & p {
    max-width: 60%;
    margin: 0 auto 0 auto;
  }
}

.makeHome {
  h3 {
    font-size: $font-size-5xl;
    font-weight: 700;
    margin-bottom: $spacing-md;
    padding-bottom: $spacing-md;
    position: relative;
    margin: 0px;
  }
  .MuiAvatar-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    font-size: $font-size-5xl;
    width: $spacing-8xl;
    height: $spacing-8xl;
    border-radius: $border-radius;
    line-height: 0;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
    background-color: $primary-white;
    transition: 0.3s;
    img {
      max-width: 35px;
      max-height: 35px;
    }
  }
  .MuiListItemText-primary {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: $font-size-xxl;
    color: $color-secondary;
  }
  .MuiListItem-root {
    padding-left: 0px;
    padding-bottom: 18px;
    padding-top: 18px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
  .el-makeHomeImg {
    img {
      border-radius: 10px;
      min-height: 400px;
    }
  }
  .propertySlider {
    padding: 0px !important;
  }
}
.El-paymentTitle {
  background: #535353;
  color: $primary-white;
  text-align: center;
  padding: 10px 0px;
  font-size: 22px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 40px;
    margin-right: 10px;
    max-height: 40px;
  }
  b {
    padding-left: 6px;
  }
  @media (max-width: 767px) {
    font-size: $spacing-sm !important;
    img {
      max-width: 28px;
      margin-right: 8px;
      max-height: 28px;
    }
  }
}
button.El-ExpandButtonWeb {
  font-size: 19px;
  z-index: 1;
  height: 62px;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 84%, white 100%); */
  text-transform: capitalize;
  /* padding-top: 40px; */
  width: 184px;
  margin: 60px auto 0 auto;
  display: flex;
  background: #000;
  color: #ffffff;
  border-radius: 8px;

  @media (max-width: 767px) {
    font-size: 16px;
    height: 46px;
    width: 162px;
    margin: 44px auto 0 auto;
  }
}
button.El-ExpandedButtonWeb {
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  text-decoration: underline;
  color: $primary-black;
  text-transform: capitalize;
  background: none;
}
.propertySliderInner {
  padding: 0 16px;
}
.EL-homeproPertyList {
  min-height: 600px;
}
// menu css start
@media (max-width: 768px) {
  .makeHome {
    padding-top: $spacing-md !important;
    padding-bottom: $spacing-sm !important;

    .el-makeHomeImg {
    }
    .MuiAvatar-rounded {
      width: 35px;
      height: 35px;
      margin-right: $spacing-sm;
      img {
        max-width: 25px;
        max-height: 25px;
      }
    }
    .MuiListItem-root {
      padding-left: 0px;
      padding-bottom: 10px;
      padding-top: 0px;
    }
    .MuiListItemText-primary {
      font-size: $font-size-default;
    }
    h3 {
      font-size: $font-size-lg;
      padding-bottom: $spacing-sm;
    }
    .el-makeHomeImg {
      img {
        min-height: inherit;
      }
    }
  }
  .EL-homeproPertyList {
    min-height: 500px;
  }
  .propertySlider {
    padding: 0px !important;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
  .el-section {
    padding: 25px 0 20px 0;
    &.EL-homeproPertyList {
      padding: 56px 0 20px 0 !important;
    }
    .el-testimonial {
      padding: 0 8px;
    }
    &.section-bg {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 25px;
    }
  }
  .EL-bannerSkeleton {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .elMainBanner {
    min-height: inherit !important;
    a,
    button {
      margin-top: 26px;
    }
  }
  .el-section.el-guest {
    // padding-top: 5px;
  }
  .section-header {
    padding-bottom: 13px;
    h2,
    h1,
    h3 {
      font-size: $font-size-xl;
      margin: 0 -8px $spacing-xs -8px;
      z-index: 2;
      &:before {
        margin: 0 10px 4px 0;
      }
      &:after {
        margin: 0 0 4px 10px;
      }
      &:before,
      &:after {
        content: '';
        width: $spacing-md;
      }
    }
    p {
      max-width: 100%;
    }
  }
  .elMainBanner {
    display: none;
    .sliderItem {
      min-height: inherit !important;
      height: auto;
      margin-bottom: 0px;
      .lazy-load-image-background {
        height: 446px !important;
      }
      img {
        height: auto;
        width: 100%;
        object-fit: fill;
      }
    }
    h2 {
      // position: absolute;
      width: 100%;
      left: inherit;
      right: inherit;
      color: $primary-white;
      font-size: 30px;
      padding: 0 0px;
      text-align: center;
      z-index: 9;
      .el-hometitle {
        display: block;
        position: relative;
        padding-bottom: $spacing-md;
      }
    }
  }
}
.trustElementSkeleton {
  .MuiSkeleton-text {
    transform: inherit !important;
  }
}
