@import '@styles/_variables.scss';
.slekton-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    width: 31%;
    margin-bottom: 10px;
    margin-right: 10px;
    .MuiSkeleton-root {
      width: 31%;
      margin-bottom: $spacing-xxs;
    }
  }
  h1 {
    margin-right: $spacing-sm;
  }
  .MuiSkeleton-root {
    margin-right: $spacing-xxs;
    position: relative;
    width: 100%;
    &:last-child {
      margin-right: 0px;
    }
  }

  @media (max-width: 768px) {
    div {
      width: 36%;
      margin-right: 0px;
      .MuiSkeleton-root {
        margin-bottom: $spacing-xxs;
        margin-right: 0px;
      }
    }
  }
}
