@import '@styles/_variables.scss';
.transOff {
  .MuiSkeleton-root {
    transition: inherit !important;
    -webkit-transform: inherit;
  }
}

.propertySlider {
  position: relative;
  overflow: inherit;
  padding: 0 0px !important;
}
.el-listing-container {
  .service-item {
    box-shadow: 0px 3px 16px 0px #00000026;
    transition: 0.3s;
    border-radius: 0px;
    overflow: hidden;
    margin-bottom: $spacing-3xs;
    position: relative;
    padding: 10px 10px 0px 10px;
    .EL-ListingMatterportChip {
      position: absolute;
      bottom: 22px;
      right: 10px;
      z-index: 1;
      background-color: #fff;
      font-size: 12px;
      height: 28px;
      padding: 0 4px;
      color: #666;
      font-weight: 600;
      svg {
        max-width: 22px;
        color: #666;
        path {
          fill: #666;
        }
      }
    }
  }
  .property-navigation {
    .property-next {
      color: $gray-dark;
      margin-bottom: -25px;
      margin-right: -25px;
      &:after {
        font-size: 50px;
      }
    }
    .property-prev {
      color: $gray-dark;
      margin-bottom: -25px;
      margin-left: -25px;
      &:after {
        font-size: 50px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      color: #fff;
    }
  }
  .el-priceBox {
    display: block;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    align-items: center;
    color: $primary-black;
    .el-sf {
      font-size: 12px;
      color: #666;
    }
    h4 {
      display: flex;
      align-items: center;
      font-style: normal;
      font-size: 24px;
      em {
        font-style: normal;
        font-size: 12px;
        color: #666;
        padding-left: 2px;
        margin-bottom: -9px;
      }
    }
    svg {
      font-size: 19px;
    }
    .EL-info {
      display: none;
    }
  }
  .amenitiesCardMain {
    button {
      white-space: nowrap;
      font-size: $font-size-default;
      text-transform: uppercase;
      width: auto;
      font-weight: $font-weight-bold;
      border-radius: 6px;
      box-shadow: none;
      margin-top: $spacing-3xs;
      background: none;
      border-radius: $border-radius-xl;
    }
    .el-homeAmenity {
      cursor: pointer;
      display: inline-table;
      padding: 0 8px;
      text-align: center;
      min-width: 84px;
      &:first-child {
        padding-left: 0px;
      }
      &.amenity {
        .MuiChip-avatar {
          width: $space-22px;
          height: $space-22px;
          margin: 0px auto;
        }
        span {
          padding: 0px;
          padding: 0px;
          white-space: normal;
          white-space: normal;
          max-width: 65px;
          display: block;
          overflow: inherit;
          text-overflow: inherit;
          margin: 0 auto;
          line-height: 15px;
          font-size: 13px;
          margin-top: 4px;
        }
      }
    }
    .EL-metrics {
      font-size: 14px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 0px;
      span {
        white-space: nowrap;
      }
      hr {
        margin: 2px 10px;
        border-color: #999999;
        &:last-child {
          display: none;
        }
      }
    }
  }
  .MuiCardContent-root {
    cursor: pointer;
    padding: 0px 0px 0px 0px !important;
    a {
      text-decoration: none;
    }
  }
  .el-priceGrid-main {
    border-top: 1px dashed #ccc;
    margin-top: 6px;
    .el-priceGrid {
      padding-top: 8px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      a,
      button {
        width: auto;
        margin: 0px;
        color: #458df8;
        text-transform: inherit !important;
        margin-bottom: 5px;
        border: 1px solid;
        padding: 4px 16px;
        &:hover {
          color: #0f6fff;
        }
        .MuiButton-endIcon {
          margin-left: 0px;
          svg {
            font-size: 27px;
          }
        }
        h4 {
          color: $primary-black;
        }
      }
    }
  }
  .lazyProgress {
    min-height: 300px;
  }
  .ElPropertyWeb {
    padding-bottom: $spacing-md;
    padding-left: 10px;
    padding-right: 10px;
  }

  .el-PRating {
    position: relative;
    .MuiChip-root span {
      color: #666;
    }
    .MuiChip-filled {
      border-radius: 5px;
      padding: 0 10px;
      font-weight: $font-weight-bold;
      img {
        max-width: 18px;
      }
    }
  }
  .swiper-pagination {
    display: none;
  }
  .EL-topAmenitiesList {
    display: flex;
    align-items: flex-start;
    height: auto;
    overflow-y: auto;
    padding: 12px 0px 8px 0px;
    border-top: 1px dashed #ccc;
    margin-top: 6px;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    & > span {
      display: ruby;
    }
  }
  @media (max-width: 767px) {
    .propertyName {
      .post-title {
        white-space: inherit;
        overflow: inherit;
        text-overflow: inherit;
      }
    }
    .ElPropertyWeb {
      max-height: 1200px;
      min-height: inherit;
    }
    .ElPropertyMobile {
      padding: 0 16px;
      &.El-expanded {
        max-height: inherit;
      }
    }
    .El-ExpandButton {
      color: $primary-black;
      font-weight: $font-weight-bold;
      text-decoration: underline;
      font-size: $font-size-lg;
      z-index: 1;
      height: 40px;
      background: #000;
      text-transform: capitalize;
      color: #fff;
      margin-top: 16px;
    }
    .El-ExpandedButton {
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      text-decoration: underline;
      color: $primary-black;
      text-transform: capitalize;
      background: none;
      margin-top: $spacing-sm;
      &:hover {
        background: none;
      }
    }

    .lazyProgress {
      min-height: 200px;
    }
    min-height: 300px;
    .propertyName {
      .post-title {
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        font-size: 18px;
        min-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .post-location {
        margin-bottom: 2px;
        margin-left: -2px;
      }
    }
    .MuiCardContent-root {
      padding: $spacing-xxs 0px 10px 0px;
    }

    .el-priceGrid-main {
      display: block;
      width: 100%;
      .el-priceGrid {
        padding-top: 8px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        a,
        button {
          width: auto;
          margin: 0px;
          color: #458df8;
          text-transform: inherit !important;
          margin-bottom: 4px;
          min-width: 100px;
          .MuiButton-endIcon {
            margin-left: 0px;
            svg {
              font-size: 27px;
            }
          }
          h4 {
            color: $primary-black;
          }
        }
      }
      .MuiSkeleton-text {
        transform: inherit;
        margin-top: 6px;
      }
    }
    .MuiCardContent-root {
      padding: $spacing-xxs 0px 0px 0px !important;
    }
    .amenitiesCardMain {
      padding: 0px 0 0 0px;
      .EL-metrics {
        font-size: 14px;
      }
    }
    .el-priceBox {
      width: 100%;
      margin-left: -2px;
      h4 {
        font-size: 20px;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
      }
      .swiper-pagination-bullet-active {
        background-color: #0e5a8e;
      }
    }

    .service-item {
      margin-bottom: 0px;
      border: none;
      box-shadow: 0px 3px 16px 0px #00000026;
    }
    .swiper-horizontal {
      overflow: inherit;
    }
    .swiper-pagination {
      display: block;
      bottom: -6px;
      .swiper-pagination-bullet {
        background-color: $primary-black;
      }
    }
    .el-homeAmenity {
      &.amenity .MuiChip-avatar {
        width: $space-22px;
        height: $space-22px;
        margin-left: 0px;
      }
    }
  }
}
