@import '@styles/_variables.scss';
.El-myWalletFaqSkeletonMain {
  margin-top: 40px;
  min-width: 320px;
  width: 100%;
  .El-myWalletFaqSkeleton {
    padding: 16px 16px;
  }
}
.EL-faqWallet {
  background: #1c1c1c;
  z-index: 1;
  position: relative;
  padding: 24px 0px;
  h4 {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .el-detailFaqList {
    padding: 0 12px 10px 12px;
    p {
      margin: 0px;
      // line-height: $spacing-sm;
      color: $gray-medium-dark;
      font-size: 12px;
      position: relative;
      display: flex;
    }
    h6 {
      font-size: $font-size-default;
      margin: $spacing-xxs 0px;
      display: block;
      padding: 0px;
      line-height: $space-22px;
      position: relative;
      display: flex;
      font-weight: $font-weight-bold;
    }
  }
  .MuiPaper-root {
    box-shadow: none;
    margin-bottom: $spacing-xxs;
    margin-top: 0px !important;
    background: none;
    &.Mui-expanded {
      background: #2b2b2b;
    }
    svg {
      color: #fff;
    }
  }
  .MuiButtonBase-root {
    font-size: 1em;
    background: none;
    min-height: inherit;
    padding: $spacing-xxs $spacing-sm;
    font-weight: $font-weight-bold;
    border-radius: $border-radius;
    min-height: inherit !important;
    p {
      margin: 0px;
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: #fff;
    }
    .MuiAccordionSummary-content {
      margin: 0px !important;
    }
  }

  .MuiAccordionDetails-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-width: 767px) {
    .MuiAccordionDetails-root {
      padding: 0px $spacing-3xs;
    }
    .el-detailFaqList > div {
      p {
        line-height: $spacing-md;
      }
      h6 {
        margin: $spacing-xxs 0px 4px 0px;
      }
    }
  }
}
