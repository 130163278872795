@import '@styles/_variables.scss';
.el-loginModal,
.loginMobileModal {
  .MuiDrawer-paper {
    min-height: 80vh;
  }
  .MuiDialog-paperFullWidth {
    max-width: 510px;
    border-radius: $spacing-sm;
  }
  .el-loginContent {
    text-align: center;
    padding-bottom: 40px;
    .el-loginTitle {
      text-align: center;
    }
    .el-signupTitle {
      text-align: left;
      margin-bottom: 16px;
      font-weight: 600;
      .MuiTypography-root {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .el-loginTitle {
      font-size: $font-size-3xl;
      font-weight: $font-weight-bold;
      margin-bottom: 40px;
      .el-loginLogo {
        margin: 0 0 $spacing-xs;
        .MuiAvatar-root {
          /* background: $gray-pastel; */
          padding: $spacing-xxs;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
          box-sizing: initial;
          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }
    }
  }
  .el-loginHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  .MuiDialogContent-root {
    .MuiButton-contained {
      border-radius: 24px;
      box-shadow: none;
      background: #000;
      text-transform: capitalize;
      height: 48px;
      &.Mui-disabled {
        background-color: #b2b2b2;
        color: #fff;
      }
    }
    .el-loginFieldMain {
      min-height: 265px;
      &.EL-loginForm {
        max-width: 360px;
        margin: 0 auto;
      }
    }
    &.EL-profileForm {
      .MuiButton-contained {
        margin-top: 12px;
      }
    }
  }

  .el-loginAction {
    font-size: $font-size-sm;
    color: $gray-medium-dark;
    justify-content: center;
    padding-top: 44px;
    a {
      color: #2563eb;
      padding-left: $spacing-3xs;
      text-decoration: none;
    }
  }

  // success message
  .El-signupSuccess {
    text-align: center;
    padding: 16px 16px 0 16px;
    min-height: 356px;

    .MuiTypography-h5 {
      font-weight: 600;
      font-size: 24px;
    }

    .MuiTypography-subtitle1 {
      line-height: 20px;
      max-width: 250px;
      margin: 0 auto;
    }
    .El-successLottie {
      width: auto;
      margin: 18px auto 10px auto;
      display: inline-block;
    }
  }
}
// menu css start
@media (min-width: 768px) {
}
@media (max-width: 767px) {
  .loginMobileModal {
    .el-loginContent {
      .el-loginTitle {
        font-size: 20px;
        margin-bottom: 1rem;
      }
      .el-signupTitle {
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 600;
        .MuiTypography-root {
          font-size: 14px;
        }
      }
      .el-loginField label {
        font-size: 14px;
      }
    }
    .MuiDrawer-paper {
      -webkit-border-top-left-radius: 0px;
      -webkit-border-top-right-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-topright: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .el-loginHeader {
      margin-bottom: 0px;
      padding-right: 18px;
      justify-content: flex-end;
    }

    .el-loginContent {
      padding-left: 24px;
      padding-right: 24px;
      max-width: 500px;
      padding-top: 0px;
      .MuiTextField-root {
        .MuiInputBase-input {
          color: #000;
          height: 30px;
        }
      }
    }
    .el-loginAction {
      display: block;
      text-align: center;
      max-width: 300px;
      margin: 0 auto 0px auto;
      padding-top: 24px;

      a {
        margin: 0px;
      }
    }
    .MuiDialogContent-root {
      .el-loginFieldMain {
        min-height: inherit;
      }
    }
  }

  .El-signupSuccess {
    padding: 30% 16px 0 16px;
  }
}
