@import url('https://fonts.googleapis.com/css2?family=Scheherazade+New:wght@400;500;600;700&display=swap');
.El-walletMain {
  background-color: #000;
  min-height: 800px;
  .El-walletMainWraper {
    position: relative;
    z-index: 1;
    h4 {
      font-size: 22px;
      font-family: 'Scheherazade New', serif;
    }
  }

  .MuiIconButton-root {
    padding-left: 24px;

    svg {
      color: #fff;
    }
  }
  .MuiBox-root {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 100%;
    height: 200px;
    position: absolute;
    overflow: hidden;
    -webkit-border-bottom-right-radius: 100px;
    -webkit-border-bottom-left-radius: 100px;
    -moz-border-radius-bottomright: 100px;
    -moz-border-radius-bottomleft: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    top: -127px;
    background: #e290ff;
    background: radial-gradient(circle, #e290ff 0%, black 51%);
    opacity: 0.5;
    left: 0px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 500px;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    top: 0px;
    background: #e290ff;
    background: radial-gradient(circle, #e290ff 0%, black 51%);
    opacity: 0.2;
  }

  h6 {
    margin: 0 auto;
    max-width: 250px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-family: 'Scheherazade New', serif;
    font-weight: 400;
    color: #ccc;
    b {
      font-weight: 400;
      font-family: 'Scheherazade New', serif;
      color: #fff;
    }
  }
  .MuiCard-root {
    &.EL-elivashPaper {
      color: #fff;
    }
  }
  h4 {
    color: #fff;
    font-size: 20px;
    padding: 0 16px;
  }
}

.El-walletTopSection {
  padding: 0 16px;
  .EL-elivaasLuxe {
    margin: 0 auto;
    display: block;
  }
  .EL-memberIconTop {
    margin: 16px auto 12px auto;
    display: block;
  }
  .El-myElivash {
    background: #222222ad !important;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    z-index: 9;
    position: relative;
    color: #fff !important;
    margin-top: 24px;
    border-radius: 8px;
    .MuiIconButton-root {
      padding: 0px;
    }
    .MuiTypography-body2 {
      color: #999;
      font-size: 12px;
    }
    .MuiTypography-body1 {
      font-size: 18px;
    }
    .EL-elicashIcon {
      width: 68px;
      height: auto;
    }
    .EL-myCashIn {
      width: 100%;
      padding: 0 16px;
      margin-top: -4px;
    }
  }
}
.EL-elivash {
  padding: 0 16px;
  h4 {
    padding: 0px;
    margin-bottom: 16px;
  }
  .EL-elivashInner {
    background: #222222cc !important;
    padding: 12px 16px;
    z-index: 9;
    position: relative;
    color: #fff !important;
    .EL-elicash {
      width: 24px;
      margin-right: 8px;
    }
    .MuiTypography-body1 {
      display: flex;
      align-items: center;
      font-size: 12px;
      b {
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .MuiTypography-body2 {
      font-size: 14px;
      color: #999;
    }
    h5 {
    }
  }
  .MuiTypography-caption {
    color: #fff;
    padding: 12px 0 0 0px;
  }
}
.EL-wallerSpend {
  color: #fff;
  .MuiListItem-root {
    font-size: 12px;
    .MuiListItemText-root,
    .MuiTypography-root {
      color: #999;
      font-size: 14px;
    }
    .MuiListItemText-root {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #e8e8e8;
        margin-right: 4px;
      }
      &.EL-cashPending {
        &:before {
          background: #ff988b;
        }
      }
    }
  }
  p {
    color: #999;
    font-size: 14px;
    margin-bottom: 8px;
    b {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
.EL-elicashSlidermain {
  padding: 24px 16px 20px 16px;
  h4 {
    padding: 0px;
    margin-bottom: 16px;
  }
}

.EL-wallerCard {
  &.MuiCard-root {
    padding: 32px 16px;
    background: #1b1b1b;
    display: block;
    margin-top: 24px;
    -webkit-border-top-left-radius: 26px;
    -webkit-border-top-right-radius: 26px;
    -moz-border-radius-topleft: 26px;
    -moz-border-radius-topright: 26px;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
  }

  .MuiSlider-root {
    .MuiSlider-markLabel {
      color: #ccc;
      max-width: 50px;
      white-space: inherit;
      text-align: center;
    }
    .MuiSlider-rail {
      height: 8px;
      color: #fff;
      opacity: 0.15;
    }
    .MuiSlider-thumb {
      color: #c1c1c1;
    }
    .MuiSlider-track {
      color: #c1c1c1;
      height: 6px;
    }
    .MuiSlider-mark {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      color: #6e6f7b;
      background: #6e6f7b;
    }
  }
}

.EL-walletFaqMain {
  padding: 24px 0 0 0px;
  h4 {
    margin-bottom: 16px;
  }
}

.EL-memberStepper {
  padding: 16px 0px;
  border-top: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
  min-height: 91px;
  margin-top: 16px;
}

.EL-memberUpgrade {
  padding: 24px 0px 0 0px;
  display: grid;
  gap: 16px;
  h5 {
    font-size: 12px;
    color: #999;
    b {
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      padding-left: 4px;
    }
  }
  button {
    font-family: 'Scheherazade New', serif;
    font-size: 16px;
    color: #000;
    height: 48px;
    white-space: nowrap;
    min-width: 130px;
  }
  .EL-walletBook {
    background: #fff;
  }
  .EL-walletPlan {
    background: #fde09a;
  }
}
