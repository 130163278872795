@import '@styles/_variables.scss';

.El-filterbar-continer {
  width: 100%;
  max-width: 1248px;
  position: relative;
  z-index: 2;
  margin: 0 auto;

  .property-filter-bar {
    position: relative;
    display: flex;
    justify-content: center;

    h6 {
      font-size: 20px;
      color: #000;
    }
  }
}
.el-search {
  margin-bottom: 0px;

  &-content {
    text-align: left;
    color: $primary-black;
    text-transform: none;
    min-height: 30px;
    &-header {
      &.MuiTypography-root {
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    &-body {
      display: flex;
      gap: 2px;
      h6 {
        font-size: 12px;
        font-weight: 400 !important;
      }
    }
  }
  .MuiTypography-root {
    text-transform: none;
  }
  & .MuiButtonBase-root.MuiButton-root {
    display: flex;
    justify-content: flex-start;
    background-color: $primary-white;
    color: $primary-black;
    border-radius: $spacing-xxs;
    box-shadow: 0px 2px 8px 0px #00000026;
    font-size: $font-size-md;
    font-weight: 400;
    padding: 14px 12px 11px 12px;
  }
  &-searchFilledBox {
    & .MuiButtonBase-root.MuiButton-root {
      display: flex;
      justify-content: space-between;
      background-color: $primary-white;
      color: $primary-black;
      border-radius: $spacing-xxs;
      box-shadow: 0px 2px 8px 0px #00000026;
      font-size: $font-size-md;
      font-weight: 400;
      padding: 4px 12px;
      min-height: 55px;
      .MuiButton-endIcon {
        svg {
          font-size: 26px;
        }
      }
    }
  }
}

.mobileSearchDrawer {
  .MuiDrawer-paper {
    box-shadow: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0px !important;
    background-color: $primary-white;
  }
}
