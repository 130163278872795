@import '@styles/_variables.scss';
.el-loginBox {
  label {
    font-size: $font-size-default;
    // font-weight: $font-weight-bold;
    font-weight: 600;
    transform: inherit;
    span {
      color: #000;
    }
  }
  .MuiTextField-root {
    position: relative;
    // margin-bottom: 12px;
    .Mui-error {
      &.MuiFormHelperText-contained {
        position: absolute;
        bottom: -19px;
        margin: 0px;
        color: #ff4f4f;
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: #ccc;
      }
    }
  }
}
.el-loginField {
  label {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
    // font-weight: $font-weight-bold;
  }
  .MuiInputBase-root {
    border-radius: 8px;
    input.MuiInputBase-input {
      height: 31px;
    }
  }
}
.el-googleLogin {
  border-color: $gray-pastel !important;
  color: $gray-menu !important;
  font-weight: 600 !important;
  font-size: $font-size-md !important;
  img {
    max-width: $spacing-xl;
  }
}
.el_resendOtp {
  padding: 24px 0 0px 0px;
  text-align: center;
  font-size: $font-size-default;
  color: $gray-medium-dark;
  color: #888;
  font-weight: 600;
  p {
    margin: 0px;
  }
  button {
    font-size: 14px;
    font-weight: $font-weight-bold;
    text-decoration: underline;
    border-radius: 0px;
    padding-bottom: 0px;
    padding: 0px;
    color: #2563eb;
  }
}
// menu css start
@media (min-width: 768px) {
}
@media (max-width: 767px) {
  .el_resendOtp {
    padding: 20px 0 0px 0px;
    a {
      margin: 0px;
    }
    button {
      margin-top: 0px;
    }
  }
}
