/* Import other CSS files or libraries here if needed */

/* Import your custom variables and mixins */
@import 'variables';
@import 'mixins';
@import '@styles/_variables.scss';
:root {
  font-family: 'Source Sans Pro', sans-serif, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* Global styles */
html,
body {
  margin: 0;
  font-size: $font-size-md;
  background: $primary-white !important;
  color: $color-secondary !important;
  /* Add any other global styles here */
}
.grayscale {
  filter: grayscale(1);
  pointer-events: none;
  height: 100%;
  position: fixed;
  width: 100%;
}

/* Typography */
p {
  /* Define styles for paragraph text here */
}
.MuiContainer-maxWidthLg {
  max-width: 1368px !important;
  margin: 0 auto;
}
/* Main page-specific styles */
.mainPage {
  padding-top: 0px;
  /* Add styles for the main page here */
}
button {
  text-transform: capitalize !important;
  font-weight: 600 !important;
}
a.MuiButton-root {
  text-transform: capitalize !important;
  font-weight: 600 !important;
}
/* Utility classes for width and height */
.full-width {
  max-width: 100%;
  height: auto;
  text-align: center;
}

.full-width img {
  max-width: 100%;
  max-height: 100%;
}

/* Typography and links */
* {
  font-family: 'Source Sans Pro', sans-serif;
}

a {
  text-decoration: none;
}

p {
  line-height: $spacing-lg;
}

/* Headings */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

/* Custom heading class */
.h11 {
  color: $primary-black;
}

/* Clickable elements */
.clickable {
  cursor: pointer;
}

/* Hidden elements */
.hidden {
  display: none !important;
}

/* Text alignment classes */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Font size classes */
.font-xs {
  font-size: 0.5em;
}

.font-sm {
  font-size: 0.75em;
}

.font-m {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.25em;
}

.font-xl {
  font-size: 1.5em;
}

/* Font weight class */
.font-b {
  font-weight: bold;
}

/* Dark mode styles */
.dark .markdown-body pre {
  background-color: #424242;
}

/* Table styles */
table {
  width: 100%;
  text-align: left;
}

/* Flexbox utility classes */
.flex {
  display: flex;
  display: -webkit-flex;
  /* Add more flexbox utility classes here */
}

/* Spacing between flex items */
.flex-j-fe {
  justify-content: flex-end;
}

.flex-a-fs {
  align-items: flex-start;
}

.flex-ac {
  align-items: center;
}

.flex-jc {
  justify-content: center;
}

.flex-ajc {
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-5 {
  flex: 5;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Spacing between flex items */
.sp-bw {
  justify-content: space-between;
}

.sp-a {
  justify-content: space-around;
}

/* Align center utility class */
.ai-center {
  align-items: center;
}

/* Full width utility class */
.full-width {
  width: 100%;
}

/* Positioning classes */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/* Width utility classes */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

/* Margin utility classes */
.mt-0 {
  margin-top: 0 !important;
}

.mt-8 {
  margin-top: $spacing-xxs;
}

.mt-12 {
  margin-top: $space;
}

/* Add more margin utility classes here */

/* Padding utility classes */
.p-16 {
  padding: $space;
}

.p-24 {
  padding: $spacing-lg;
}

/* Background color utility class */
.bg-white {
  background-color: $primary-white;
}

/* Font weight utility class */
.fw-bold {
  font-weight: bold;
}

/* Font size utility classes */
.fs-12 {
  font-size: $spacing-xs;
}

.fs-16 {
  font-size: $font-size-md;
}

/* Add more font size utility classes here */

/* Color utility classes */
.color-white {
  color: $primary-white !important;
}

.color-black {
  color: $gray-extra-dark !important;
}

.color-blue {
  color: $color-secondary-dark;
}

.color-grey {
  color: $gray-medium-dark;
}

.color-primary {
  color: $color-primary;
}

/* Opacity utility classes */
.opacity-6 {
  opacity: 0.6;
}

.opacity-8 {
  opacity: 0.8;
}

/* Add more opacity utility classes here */
