@import '@styles/_variables.scss';

.El-content-container {
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 32px 40px 32px 40px !important;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;
  .MuiInputAdornment-root {
    margin-right: 2px;
    svg {
      margin-right: 4px;
    }
  }
  .MuiSelect-select {
    text-transform: capitalize;
  }
  &.listing {
    padding: 0px !important;
    margin-bottom: 0px;
  }

  &.callback {
    padding: 0px !important;
    margin-bottom: 0px;
  }

  .content-header {
    p {
      font-weight: normal;
      font-size: 18px;
      color: #000;
      font-weight: 600;
    }
  }

  .el-fields-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 28px;
    fieldset {
      border-color: #e0e0e0;
    }
    &.callback {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;

      .el-cityList-container {
        width: 100%;
      }

      .el-datefields-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .CheckinCheckoutField {
          width: 50%;
        }
      }

      .el-guestButton-container {
        width: 100%;
        margin-bottom: 10px;
      }
      .el-search-container {
        display: none;
      }
    }

    &.MuiButtonBase-root {
      width: 100%;
    }

    .el-cityList-container {
      width: 20%;
    }

    .el-datefields-container {
      width: 40%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .CheckinCheckoutField {
        width: 50%;
        svg {
          margin-right: 10px;
          color: #00000099;
        }
      }
    }

    .el-guestButton-container {
      width: 20%;
      position: relative;
      .El-addGuestButton {
        height: inherit;
        background: $primary-white !important;
        color: $primary-white;
        text-transform: none !important;
        color: $primary-black;
        border: 1px solid #e0e0e0 !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 56px;
        .El-addGuestButtonInner {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
        }
        svg {
          margin-right: 6px;
          color: #00000099;
        }

        &:hover {
          background-color: initial;
          color: initial;
        }
      }
    }
    .el-search-container {
      width: 20%;
      .el-search-btn {
        &.MuiButtonBase-root {
          width: 100%;
          height: inherit;
          background-color: $primary-black;
          color: $primary-white;
          &.Mui-disabled {
            background: $gray-pastel;
            color: $gray-medium-dark;
          }
        }
        &.MuiButtonBase-root.MuiButton-root:hover {
          &:hover {
            background-color: rgba($primary-black, 0.8);
            color: $primary-white;
          }
        }
      }
    }
  }
  .el-search-container {
    .MuiButtonBase-root {
      background-color: $primary-black;
      padding: 14px 12px;
      border-radius: 8px;
    }
  }

  .MuiFormControl-root {
    display: flex;
    align-items: center;

    .MuiInputLabel-root {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 5px;

      .MuiInputAdornment-root {
        margin-right: 8px;
      }
    }
  }

  .CheckinCheckoutField {
    .MuiTextField-root {
      .MuiFormLabel-root {
        // left: -12px;
        color: $primary-black;
        font-weight: $font-weight-bold;
        &.MuiInputLabel-shrink {
          .El-calLabel {
            color: #666;
            font-size: 18px;
            svg {
              margin-right: 4px;
            }
          }
        }
      }

      .MuiInputBase-root {
        background: none !important;
        border-radius: 8px !important;
        border: 1px solid #e0e0e0;
        &:after,
        &:before {
          display: none;
        }
        fieldset {
          // border-color: #e0e0e0;
        }
        input {
          color: $primary-black;
          font-weight: $font-weight-bold;
          font-size: $font-size-md;
          padding-left: 0;
          margin-left: 14px;
        }
      }
    }

    .El-calLabel {
      display: flex;
      align-items: center;
      color: #666;
      font-weight: 400;

      svg {
        font-size: $font-size-xxl;
        color: #666;
        margin-right: 6px;
      }
      &Selected {
        display: flex;
        align-items: center;
        color: #111;
        font-weight: 400;

        svg {
          font-size: $font-size-xxl;
          color: #111;
          margin-right: 6px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .El-content-container {
    padding: 0px !important;
    gap: 42px;
    &.listing {
      padding: 0px !important;
      margin-bottom: 0px;
    }

    &.callback {
      padding: 0px !important;
      margin-bottom: 0px;
    }

    .content-header {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      gap: 12px;
      box-shadow: 0px 2px 16px 0px #00000026;
    }

    .el-fields-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 28px;
      padding: 0px 20px;

      &.MuiButtonBase-root {
        width: 100%;
        border-radius: 8px;
      }

      &.callback {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 28px;
        padding: 0px;

        .el-cityList-container {
          width: 100%;
        }

        .el-datefields-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          .CheckinCheckoutField {
            width: 50%;
          }
        }

        .el-guestButton-container {
          width: 100%;
          margin-bottom: 10px;
        }
        .el-search-container {
          display: none;
        }
      }
      .el-cityList-container {
        width: 100%;
        .MuiInputBase-root {
          border-radius: 8px !important;
          .MuiInputBase-input {
            text-transform: capitalize;
          }
        }
      }
      .el-datefields-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .CheckinCheckoutField {
          width: 50%;
        }
      }
      .el-guestButton-container {
        width: 100%;
        .El-addGuestButton {
          display: flex;
          justify-content: flex-start;
        }
      }
      .el-search-container {
        width: 100%;
        .el-search-btn {
          &.MuiButtonBase-root {
            width: 100%;
            height: inherit;
            border-radius: 24px !important;
            &.Mui-disabled {
              background: $gray-pastel;
              color: $gray-medium-dark;
            }
          }
        }
      }
    }
  }
}
