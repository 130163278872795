header.EL-myWalletHeader {
  position: absolute !important;
  top: 0px;
  background: none;
  box-shadow: none !important;
  width: 100% !important;
  left: 0px;
  color: #fff !important;
  z-index: 1;
  @media (min-width: 768px) {
    top: 8px;
  }
  .MuiButton-text {
    text-decoration: underline;
    text-decoration: underline;
    padding: 0px !important;
    min-width: inherit;
  }
  .MuiIconButton-root {
    margin-right: 0px;
    svg {
      max-width: 22px;
    }
  }
  .MuiTypography-h6 {
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
  }
}
.El-myWalletSkeletonMain {
  margin-top: 40px;
  .El-myWalletSkeleton {
    padding: 16px 16px;
  }
}

.EL-MyWalletCardMain {
  overflow: hidden;
  position: relative;
  @media (min-width: 768px) {
    -webkit-border-top-left-radius: 24px;
    -webkit-border-top-right-radius: 24px;
    -moz-border-radius-topleft: 24px;
    -moz-border-radius-topright: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}
.EL-MyWalletCard {
  height: 200px !important;
  width: 100%;
  border-radius: 8px;
  height: 164px;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 24px;
  padding: 40px 0px 16px 0px;
  background-repeat: no-repeat;
  background-position: right top;
  background-color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .EL-memberCardIcon {
    max-width: 48px;
    margin: 0 auto;
  }
  .MuiTypography-body1 {
    color: #fff;
    text-align: center;
    margin: 0px;
    font-size: 16px;
  }
  .MuiCardContent-root {
    position: relative;
    z-index: 1;
    padding: 0px;
  }
  .El-wallerTopCard {
    .El-myWalletInfo {
      justify-content: center;
      display: flex;
      align-items: center;

      svg {
        max-width: 30px;
        margin-right: 8px;
      }
      b {
        font-size: 30px;
        @media (min-width: 768px) {
          font-size: 36px;
        }
      }
      span {
        color: #888;
        font-size: 16px;
        margin-bottom: -10px;
        margin-left: 4px;
        text-transform: capitalize;
      }
    }
    .El-myWalletError {
      color: #ff4f4f;
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
  &:before {
    content: '';
    width: 200%;
    height: 490px;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    top: -235px;
    background: #e290ff;
    background: radial-gradient(circle, #e290ff 0%, black 51%);
    opacity: 0.2;
    left: -50%;
  }
  @media (min-width: 768px) {
  }
}

.EL-MyWalletList {
  margin-top: -71px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  // min-height: 70vh;
  position: relative;
  min-height: 450px;
  .MuiTabs-fixed {
    background: #353a3d;
    & .MuiTabs-indicator {
      display: none;
    }
    button {
      color: #aeb0b1;
      position: relative;
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      &.Mui-selected {
        color: #fff;
      }
      &:after {
        content: '';
        position: absolute;
        height: 20px;
        right: 0px;
        top: 50%;
        margin-top: -10px;
        width: 2px;
        background: #494e50;
        border-radius: 2px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      @media (min-width: 768px) {
        min-width: 104px;
      }
    }
  }
  .EL-MyWalletItems {
    padding: 0 16px;
    @media (min-width: 768px) {
      padding: 0 40px;
    }
    .MuiTypography-body1 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 80%;
      color: #212121;
    }
    .MuiListItemSecondaryAction-root {
      display: flex;
      align-items: center;
      top: 28px;
      right: 0px;
      .El-cashAmt {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #3d3d3d;
        &.info {
          color: #3bb95e;
        }
        &.error {
          color: #ff4f4f;
        }
      }

      svg {
        max-width: 16px;
        margin-right: 6px;
      }
    }
    .MuiButton-textInfo {
      padding: 0px;
      color: #2563eb;
      text-decoration: underline;
      display: block;
    }
    .MuiChip-root {
      padding: 0 4px;
      margin-right: 4px;
      text-transform: capitalize;
      &.MuiChip-filledError {
        background: #ffeded;
        color: #ff4f4e;
      }
      &.MuiChip-filledInfo {
        background: #ffecbb;
        color: #000;
      }
      &.MuiChip-colorSecondary {
        background: #e0e0e0;

        color: #666;
      }
    }

    .MuiListItem-root {
      padding-left: 0px;
      .MuiListItemText-multiline {
        gap: 0px;
        display: grid;
        @media (min-width: 768px) {
          gap: 2px;
        }
      }
      .MuiListItemText-secondary {
        display: grid;
        gap: 2px;
        @media (min-width: 768px) {
          gap: 4px;
        }
      }
      @media (min-width: 768px) {
        padding: 12px 0px;
      }
    }
  }
}
.El-walletNotfound {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 400px;
  span {
    color: #666;
    line-height: 23px;
    font-size: 16px;
  }
}
.El-MywalletFaq {
  position: relative;
  &.El-MywalletFaqDrawer {
    z-index: 9999;
  }
  .El-MywalletFaqClose {
    position: absolute;
    right: 16px;
    top: 21px;
    z-index: 9;
    padding: 0px;
  }
  .EL-faqWallet {
    // min-height: 70vh;
    // max-height: 70vh;
    background: #fff;
    @media (min-width: 768px) {
      padding: 16px;
    }
    h4 {
      color: #000;
      font-size: 18px;
      @media (min-width: 768px) {
        font-size: 22px;
        font-weight: 600;
      }
    }
    .el-detailFaqList {
      @media (min-width: 768px) {
        padding-left: 0px;
        font-size: 14px;
      }
    }
    .MuiButtonBase-root {
      p {
        color: #222;
        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
    .MuiPaper-root {
      &:before {
        display: none;
      }
      svg {
        color: #000;
      }
      &.Mui-expanded {
        background-color: #f7f7fa;
        color: #999;
        margin: 0 0 8px 0px;
      }
    }
  }
}

.El-MywalletFaqModal {
  .MuiDialog-paper {
    min-width: 900px;
  }
}
// menu css start
@media (min-width: 768px) {
}
