@import '@styles/_variables.scss';

.EL-CouponCardInnerCoupon {
  width: 100%;
  margin-right: 18px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 8px;
  padding: 10px;

  .slekton-flex {
    align-items: center;
  }
}

.EL-CouponCardInnerGrid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.EL-CouponCardInnerList {
  display: block;
  // align-items: flex-start;
  // justify-content: space-between;
  .EL-CouponCardInnerCoupon {
    width: 94%;
    margin-bottom: 16px;
    margin-top: 16px;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
}

.El-couponDetail {
  .EL-CouponCardInnerList {
    .EL-CouponCardInnerCoupon {
      margin-top: 0px;
      margin-bottom: 0px;
      &:last-child {
        display: none;
      }
    }
  }
}
.offerSection {
  .EL-CouponCardInnerList,
  .EL-CouponCardInnerGrid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .EL-CouponCardInnerCoupon {
      @media (max-width: 767px) {
        margin-right: 0px;
      }
    }
  }
}
