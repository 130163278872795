.EL-404 {
  .MuiTypography-h4 {
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .EL-404Icon {
    justify-content: center;
    align-items: baseline;
    margin-bottom: 16px;

    .material-icons {
      font-size: 100px;
      margin-right: 16px;
      margin-right: 16px;
    }
  }
}
