@import '@styles/_variables.scss';
.city-drawer {
  z-index: 9999 !important;
  .MuiDrawer-paper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    max-height: 289px;
  }
  .el-drawer-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Mui-checked {
    color: $primary-black !important;
  }
  .MuiList-root {
    padding: 0px;
    min-height: 120px;
    .MuiListItem-root {
      border-bottom: 1px solid #e5e7eb;
      padding-left: 0px;
      &:last-child {
        border-bottom: none;
      }
      label {
        width: 100%;
        margin: 0px;
        display: block;
      }
    }
  }
}

.citySelect {
  .MuiButtonBase-root {
    background-color: transparent !important;
    padding: 0px !important;
  }
  &.MuiInputBase-root {
    border-radius: 8px !important;
    font-weight: 600 !important;
    fieldset {
      border-color: #e0e0e0;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #e0e0e0 !important;
      border-width: 1px !important;
    }
  }
}
.MuiList-root {
  .EL-selectCityMenu {
    &.Mui-selected {
      background: none !important;
    }
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
    }
  }
}
.EL-selectCityMenu,
.EL-selectCityMenuMobile {
  .MuiFormControlLabel-label {
    text-transform: capitalize;
  }
}
