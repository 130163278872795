@import '@styles/_variables.scss';

.mobilePropertyListBanner {
  position: relative;
  height: 50vh;
  min-height: 140px;
  padding: 0 !important;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    background: #000;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    opacity: 0.2;
  }
  .cityPageHeader {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    left: 0px;
    justify-content: space-between;
    padding: 0px;
    .EL-contactUsSticky {
      margin-right: 16px;
      margin-top: 4px;
      color: #fff;
      border-color: #fff;
      background: #0000004a;
    }
  }
  .backButton {
    // position: absolute;
    // top: 7px;
    // left: 10px;
    color: $primary-white;
    z-index: 1;
  }
  .bannerImage {
    position: relative;
    width: 100%;
    height: 50vh;
  }
  .el-bannerTitle {
    position: absolute;
    top: 33%;
    right: 15px;
    text-align: center;
    width: 100%;
    text-align: center;
    &.MuiTypography-root {
      font-size: $font-size-9xl;
      color: $primary-white;
      font-weight: $font-weight-bold;
      text-transform: none;
      z-index: 2;
      font-size: $font-size-7xl;
      span {
        text-transform: capitalize;
      }
    }
  }
}

.el-listingSection {
  padding: 30px 0 20px 0;
}

.el-listingFilterBar {
  position: relative;
  z-index: 2;
  margin-top: -90px;
  bottom: inherit;
  top: inherit;
  padding: 0px;
  max-width: 1170px !important;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border-radius: 12px;
}

.el-listingFilterBarWithoutBanner {
  z-index: 99;
  margin-top: 110px;
  margin-left: 0;
  margin-right: 0;
  max-width: none !important;
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
  padding-bottom: 1.5rem !important;
  box-shadow: 0 11px 11px 0 #00000016;
  background-color: #fff;
}

.el-listingFilterBar-mobile {
  margin-top: -1.8rem;
}

.el-propertiesCount {
  padding-left: 17px;
  min-height: 42px;
  &.MuiTypography-root {
    color: $primary-black;
    font-size: 24px;
    font-weight: $font-weight-bold;
    text-align: left;
    margin-bottom: 0.5em;
  }
}
.El-noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 200px;
  margin: 0px 20px 80px 20px;
  padding: 10px;
  img {
    height: 284px;
    width: 284px;
  }
  .EL-noResult-action {
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
      border-radius: 24px;
      &.MuiButtonBase-root.MuiButton-root {
        padding: 10px 36px;
      }
    }
    .el-clear-filter {
      color: $primary-black;
      border: 1px solid $primary-black;
    }
    .el-noResultCallback {
      color: $primary-white;
      background-color: #ed4441;
      border: 1px solid #ed4441;
    }
  }
  .el-clear-filter {
    color: $primary-black;
    border: 1px solid $primary-black;
    border-radius: 24px;
    &.MuiButtonBase-root.MuiButton-root {
      padding: 10px 36px;
    }
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    &:hover {
      background-color: $primary-white !important;
      color: $primary-white;
    }
  }
  .no-result-h6 {
    font-weight: 600;
    font-size: 22px;
  }
  .no-result-caption {
    color: #00000099;
    font-size: 16px;
    font-weight: 400;
  }
}

.mobileHeader-search {
  margin: 15px 0px;
}

.MuiToolbar-root {
  .mobileHeader-search {
    padding-left: 12px;
    padding-right: 0px;
    .MuiButtonBase-root.MuiButton-root.El-addGuestButton {
      border: 1px solid #ccc;
      background: #f7f7fa;
      box-shadow: none;
      padding: 2px 12px;
      min-height: 52px;
    }
  }
}

.listingPropertySlider {
  padding-bottom: 30px;
}
.El-city-description {
  border-bottom: 1px solid #353a3d;
  .MuiPaper-root {
    padding: $spacing-xs $spacing-8xl;
  }
  .MuiAccordion-root {
    background-color: $primary-black;
    color: $primary-white;
  }
  .MuiAccordionSummary-root {
    font-weight: 400;
    line-height: 32px;
    .MuiTypography-root {
      font-size: $font-size-3xl !important;
      text-transform: capitalize;
    }
    .MuiSvgIcon-root {
      color: $primary-white;
      font-size: $font-size-9xl !important;
    }
  }
  .MuiAccordionDetails-root {
    color: #707479;
    .MuiTypography-root {
      font-size: 15px !important;
    }
    font-weight: 400;
    line-height: 22px;
  }
}
@media (max-width: 767px) {
  .mobilePropertyListBanner {
    margin-top: 0px;
    height: 20vh;
    &:before {
      height: 98%;
    }
    .el-bannerTitle {
      &.MuiTypography-root {
        font-size: $font-size-xl;
      }
    }
    .bannerImage {
      height: 20vh;
    }
  }
  .el-listingFilterBar {
    margin-top: 5rem;
  }
  .el-listingSection {
    padding: 16px 0 20px 0 !important;
    .listingPropertySlider {
      padding: 0 0 8px 0px;
    }
  }
  .el-propertiesCount {
    min-height: 31px;
    &.MuiTypography-root {
      font-size: 18px;
      padding: 0 16px;
      margin: 0 0 8px 0px;
    }
  }
  .El-noResults {
    height: 460px;
    gap: 15px;
    margin-bottom: 40px;
    img {
      height: 260px;
      width: 260px;
    }
    .EL-noResult-action {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  .El-city-description {
    .MuiPaper-root {
      padding: $spacing-3xs;
    }
    .MuiAccordionSummary-root {
      .MuiTypography-root {
        font-size: $font-size-md !important;
      }
      .MuiSvgIcon-root {
        font-size: $font-size-3xl !important;
      }
    }
    .MuiAccordionDetails-root {
      .MuiTypography-root {
        font-size: $font-size-sm !important;
      }
    }
  }
}
